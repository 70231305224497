@import 'styles/vars'

.user-menu
  display: flex
  align-items: center
  position: relative

  .details
    margin-left: 12px
    cursor: pointer

  .name
    color: $color-neutral-500
    font-weight: 500
    margin-right: 3px
    display: flex
    align-items: center

    span
      max-width: 150px
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis

    img
      cursor: pointer

  .role
    font-size: 12px
    color: $color-theme-primary
    font-weight: 500

  .options-menu
    position: absolute
    top: 50px
    right: 0
    width: 262px
    border-radius: 8px
    border: 0px solid var(--foreground-background-2, #F1F3F8)
    box-shadow: 0px 27px 35px 2px rgb(0 0 0 / 8%)
    padding: 8px 0px
    z-index: 1

    ul
      list-style: none
      margin: 0
      padding:0
      overflow:hidden

    li
      padding: 12px 20px
      display: inline-flex
      align-items: center
      cursor: pointer
      width: 100%
      background: $color-neutral-0
    
    li:nth-child(1) 
      box-shadow: -3px 0px 15px -2px rgba(0, 0, 0, 0.2588235294)
      border-top-left-radius: 10px
      border-top-right-radius: 10px

      img
        margin-right: 20px
