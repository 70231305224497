.update-info_image
  width: 100px
  height: 100px

  img
    width: 100%
    height: 100%
    border-radius: 50%


.main_image_section
  display: flex
  justify-content: center
  align-items: end

.camera_image img 
  margin-left: -33px

.image_section_heading h4 
    text-align: center
    font-size: 32px
    font-weight: 500
    color: #00314D

.image_section_heading p 
    text-align: center
    font-size: 20px
    font-weight: 400
    color: #80949D

.image_section_heading 
    margin-top: 10px

form.update_profile 
    margin-top: 30px

form.update_profile label 
    font-size: 16px
    color: #00314D
    font-weight: 600

form.update_profile .textfield.input-field 
    margin: 15px 0 15px 0






