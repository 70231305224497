@import 'styles/vars'

.dropdown-button
  display: inline-flex
  position: relative
  z-index: 9

  .button
    align-items: center
    display: flex

    img
      transform: rotate(90deg)
      width: 24px
      margin-left: 4px

  .dropdown-items-container
    position: absolute
    top: 50px
    display: inline-flex
    padding: 8px 0px
    flex-direction: column
    justify-content: center
    align-items: flex-start
    min-width: 292px
    right: 0px
    border-radius: 8px
    border: 0px solid #F1F3F8
    box-shadow: 0px 8px 4px 0px rgba(0, 0, 0, 0.08)

    .dropdown-item
      display: flex
      align-items: center
      justify-content: flex-start
      padding: 0 16px
      color: #030303
      height: 48px
      cursor: pointer
      // background-color: $color-neutral-0
      width: 100%
      // background: red

      img
        margin-right: 20px
      
.dropdown-button .dropdown-item 
      background: white
      box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.08)
      border-top-left-radius: 10px
      border-top-right-radius: 10px


