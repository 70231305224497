.upload-avatar
  position: relative

.icon
  width: 80px
  height: 80px
  border-radius:  50%
  margin-bottom: 20px
  background: white
  vertical-align: center
  text-align: center
  overflow: hidden
  
  button
    margin-top: 5px

.avatar-container
  display: flex

.edit-button
  position: absolute
  left: 60px
  padding: 5px
  background: white
  border-radius: 50%
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05))