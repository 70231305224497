.account-card
    // padding: 15px
    // border: 1px solid #E0E9ED
    // border-radius: 12px
    // box-shadow: 0 10px 20px 5px #0000000d
    // margin-bottom: 20px

    &.active
        border-color: #00B8D9

    .account-card-header
        display: flex
        align-items: center
        justify-content: flex-end
        gap: 15px

        .account-card-status
            padding: 5px 10px
            font-size: 16px
            border-radius: 8px
            font-weight: 500
            
            &.active
                background: #E0FFDF
                color: #178814

            &.expired
                background: #FFD3D3
                color: #A13030

    .account-card-body

        img
            width: auto
            height: 30px

        .account-card-headings
            margin-top: 15px

            .account-card-title
                font-size: 22px
                color: #000
                margin-bottom: 10px

            .account-card-sub-text
                color: #80949D
                font-size: 14px

    span.CardNumber 
        display: inline-block
        max-width: 250px
        width: 100%
        font-size: 22px
        color: #000

.button_payment.button_remove 
    background: #00b8d9
    border: 0
    padding: 17px 24px
    color: white
    font-weight: 600
    border-radius: 12px
    font-size: 18px
    text-transform: capitalize
    display: inline-block
    line-height: normal
    box-sizing: border-box
    margin-top: 30px
