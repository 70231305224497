@import 'styles/vars'

.modalPrev
  display: flex
  align-items: center
  gap: 20px

  .details
        margin-left: 16px

  .name
      font-weight: 500

  .bottom
      color: $color-neutral-400
      display: flex
      align-items: center
      margin-top: 8px
      
  .mid-dot
      color: $color-neutral-60
      margin: 0 7px
      font-size: 30px
      line-height: 1

.staff
  width: 100%
  height: 100%

  .left,
  .right
    display: flex
    align-items: center

  .staff-listing
    height: 100vh
    padding-top:50px
    padding-bottom:50px

    .list 
      padding-bottom: 10px

    .staff-item
      color: $color-neutral-900
      padding: 16px 20px
      margin-bottom: 12px
      border-radius: 8px
      border: 1px solid #F1F3F8
      background: #FFF
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.04)
      display: flex
      align-items: center
      justify-content: space-between

      .details
        margin-left: 16px

      .name
        font-weight: 500

      .bottom
        color: $color-neutral-400
        display: flex
        align-items: center
        margin-top: 8px

    .mid-dot
      color: $color-neutral-60
      margin: 0 7px
      font-size: 30px
      line-height: 1
    
    .sort-title
      margin-right:10px

.container .body .detail
  overflow: hidden
.staff-listing
  .staff-listing-container
    display: flex
    height: 100%
    overflow: hidden

    .page-header
      width: 100%
      margin-top: 0
      
      .right
        align-items: center

    .list
      align-content: baseline
      width: 100%
    
    .list-main
      width: calc(100% + 20px)
      display: flex
      flex-wrap: wrap
      margin: 0 -10px
      height: calc(100% - 125px)
      align-content: baseline
      overflow-y: auto

      .staff-item
        width: calc(33.33% - 20px)!important
        margin: 10px
        box-shadow: 0px 20px 30px 0px #00314D0D
        border: 1px solid #E0E9ED
        border-radius: 12px
        padding: 12px 12px 24px 24px
        align-items: flex-start


        @media (max-width: 1366px)
          width: calc(50% - 20px)!important

        .name
          color: #00314D
          font-size: 22px
          margin-top: 0
          font-weight: 400
        
        .email
          color: #265068
          font-size: 14px
          font-weight: 500
        
        .progress-badge
          background: #ffd3d3
          color:#a13030
          font-size: 12px
          font-weight: 600
          border-radius: 14px
          padding: 5px 10px
          text-transform: capitalize

        .date
          color: #60747E
          font-size: 14px
          font-weight: 400 
        
        .left
         align-items: flex-start

    .pending-staff
      max-width: 450px
      width: 100%
      padding-left: 20px
      border-left: 1px solid #B7C5CC
      margin-left: 24px

      .pending-staff-title
        color: #80949D
        font-size: 18px
        font-weight: 600
        margin-bottom: 25px

    &.have-pending-jobs

      .list
        width: 100%
        display: flex
        flex-wrap: wrap

        .staff-item
          @media (max-width: 1920px)
            width: calc(50% - 20px)!important
          
          @media (max-width: 1660px)
            width: calc(100% - 20px)!important
