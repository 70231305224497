@use "sass:map"
@import 'styles/vars'

.input-field
  color: map.get($text-colors, "foreground")
  .header
    padding-bottom: 8px
    font-weight: 500

   
  select
    width: 100%
    box-sizing: border-box
    outline: none
    padding: 12px 8px
    border: 1px solid #D6D9E1
    border-radius: 12px
    font-style: normal
    font-weight: 400
    line-height: 24px
    height: 50px

  option
    font-size: 14px
    padding: 30px 8px
    line-height: 24px
    font-weight: 400

  .error
    margin-bottom: 12px
    margin-top: 4px
    color: map.get($theme-colors, "error")

.required-indicator
  color: map.get($theme-colors, "error")
  margin-left: 4px
