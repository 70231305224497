@import 'styles/vars'

.app-header
  position: sticky
  top: 0
  width: 100%
  height: $height-header
  padding: 0 24px
  box-shadow: 0px 0px 4px rgba(9, 30, 66, 0.23)
  display: flex
  align-items: center
  justify-content: space-between !important
  background: white
  box-sizing: border-box
  z-index: 999

  .logo
    width: 100%

    img
      height: 40px

  .bread-crumb
    font-size: 16px
    color: #80949D

  .header-right
    width: 100%
    display: flex
    justify-content: flex-end
    gap: 20px
    align-items: center

    .search
      display: flex
      align-items: center

      span
        color: #80949D
        font-size: 16px
        display: block
        margin-left: 10px

.search-input
  display: flex
  align-items: center
  justify-content: space-between
  position: relative


.search-input .ant-input-search-button
    border-left-style: none !important
    height: 38px !important
    border-radius: 3px
    background: var(--neutral-light-n-20, #F4F5F7)
    border-color: var(--neutral-light-n-20, #F4F5F7)
    &:hover
        border-radius: 3px !important
        background: var(--neutral-light-n-20, #F4F5F7)
        border-color: var(--neutral-light-n-20, #F4F5F7)

.search-input .ant-input
  border-left-style: none !important
  border-right-style: none !important
  height: 38px !important
  border-radius: 3px !important
  background: var(--neutral-light-n-20, #F4F5F7) !important
  border-color: var(--neutral-light-n-20, #F4F5F7) !important
  &:hover
      border-radius: 3px !important
      background: var(--neutral-light-n-20, #F4F5F7)
      border-color: var(--neutral-light-n-20, #F4F5F7)
      outline: 0
  &:focus 
    box-shadow: none

.search-input .ant-select-selector 
    border-left-style: none !important
    border-radius: 3px !important
    background: var(--neutral-light-n-20, #F4F5F7) !important
    border-color: var(--neutral-light-n-20, #F4F5F7) !important
    box-shadow: none !important
    &::before
      content: ""
      background-color: #C1C7D0
      width: 1.2px
      height: calc(100% - 18px)
      display: block
      position: absolute
      right: 0
      top: 50%
      transform: translateY(-50%)

.user
  display: flex
  justify-content: center
  align-items: center