select
  width: 350px
  outline: none
  border: 1px solid #ccc
  padding: 15px
  font-size: 1.1rem
  border-radius: 0.5rem
  box-shadow: 0 1px 4px rgba(146, 161, 176, 0.15)
  cursor: pointer

  &:focus,
  &:hover
    outline: none
    border: 1px solid rgba(0, 0, 0, 0.329)

.cuDropdown svg    
  position: absolute
  top: 15px
  left: 15px