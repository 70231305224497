@import 'styles/vars'

.page-header
  height: 86px
  margin-top: -24px
  display: flex
  align-items: center
  justify-content: space-between

  .left
    height: 100%
    display: flex
    align-items: center

    img
      margin-right: 12px
      cursor: pointer

  .left,
  .right
    display: flex
    align-items: center
