@import 'styles/vars'

.customer-content
  height: 100vh
  display: flex
  align-items: center


.cardStyles
  display: flex
  justify-content: space-between
  align-items: center
  gap: 14px

.onboarding_footer_btn
  width: 100%
  position: fixed
  bottom: 0

.steps-heading
  font-weight: 600
.icons
  font-size: 50px
  color: #525252
.icons-container
  display: flex
  justify-content: center
  gap: 10px
.success-container
  display: flex
  justify-content: center
  flex-direction: column
  align-items: center
  height: 90vh

.onboarding-header
  width: 100%
  height: $height-header
  padding: 0 24px
  box-shadow: 0px 0px 4px rgba(9, 30, 66, 0.23)
  display: flex
  align-items: center
  justify-content: start
  position: relative
  top: 0
  box-sizing: border-box
  .logo
    height: 50px
    weight: 100px
  .stepper_style
    margin-left: 25%
    display: flex
    align-items: center
    .appType_heading
      font-size: 20px
      font-weight: 500

.ant-steps-item-icon
  height: 6px
  width: 6px
.icons-name
  font-weight: 500
  margin: 10px 0px

.files
  display: flex
  justify-content: space-between
  align-items: center
  padding: 8px
  width: 100%
  border: 1px solid #B7C5CC
  cursor: pointer
  border-radius: 12px
.filelist
  display: flex
  gap: 10px
  flex-wrap: wrap

.ant-steps-item-title
  font-size: 14px !important
  color: #000 !important

.color-palatte-container
  cursor: pointer
  margin: 3px
  width: 40px
  height: 40px
  border-radius: 50%
  display: flex
  justify-content: center
  align-items: center

.customer_data
  width:100%
  height:100%

.customer_data .NoData
  width:100%
  height:100%
  display: flex
  justify-content: center
  align-items: center

.card-inner
 display: flex
 gap: 15px
 align-items: center

.main_card_popup 
  background: #fff
  margin: 0 auto
  width:100%
  max-width:888px
  border-radius:12px
  height: 80%
  max-height: 850px
  display: flex
  flex-direction: column

  .footer_new 
    border-top: 2px solid #E0E9ED
    margin: 0px 40px
    padding: 25px 0
    flex-shrink: 0

    .button.new
      padding: 12px 24px 12px 24px
    
    .back-button
      color: #60747E
      font-size: 18px
      font-weight: 600
      border: none
      box-shadow: none
      display: flex
      align-items: center
        
      svg
        margin-top: 2px


  .header_new
    flex-shrink: 0


.ant-modal .ant-modal-title
  color: #00314D
  font-size: 40px
  font-weight: 600
  line-height: normal

.cu_modal_form label 
  color: #00314D
  font-weight: 600


.logo 
  position: relative
  
  .progress_bar
    position: absolute
    left: 50%
    transform: translateX(-50%)


.payment-modal-main

  .credit-card-form 
    margin: 0 auto

    .form-row 
      display: flex
      justify-content: space-between
      margin-bottom: 20px

      label 
        flex: 1
        margin-right: 10px
        color:#00314D
        font-weight:600
        font-size:16px

      input
        margin-top:10px
        border-radius:12px
        border:1px solid #B7C5CC
        height:45px

      select
       width: 50%
       padding: 0 12px
       height: 50px
       margin-top: 10px
       border-radius: 12px
       border: 1px solid #B7C5CC
  
.modal-footer
  display: flex
  justify-content: right
  gap: 10px

button.cancel_button
  border: none
  background: transparent
  color: #00B8D9
  font-weight: 600
  border-radius: 12px
  font-size: 18px
  height: auto
  padding: 13px 20px

.mastercard_ending
  border: 1px solid #E0E9ED
  width: 267px
  height: auto
  border-radius: 12px
  padding:20px
  flex-shrink:0
  box-shadow: 0px 20px 30px 0px #00314D0D


.payment_method 
  align-items: center
  margin: 40px 0px 40px 0px
  display: flex
  gap: 10px
  justify-content: space-between
  display: flex

  .check_image
    height: 24px

.mastercard_ending h4 
  font-size: 18px
  font-weight: 600
  color: #00314D
  margin-top: 20px

.check_image 
  display: flex
  justify-content: right

.mastercard_ending.active 
  border: 1px solid #00B8D9





.cardStyles .app-logo img 
    width: 40px
    height: 40px
    object-fit: contain