@use "sass:map"
@import 'styles/vars'


html
  height: 100%
body
  height: 100%
  font-family: $font-family
  font-size: 14px
  line-height: 1.2
  font-weight: 400
  color: map.get($text-colors, "slate")

#root
  height: 100%

h1
  margin: 0
  font-size: 56px
h2
  margin: 0
  font-size: 42px
h3
  font-size: 28px
  font-weight: 400
  margin: 0
h4
  font-size: 18px
  font-weight: 400
  margin: 0
h5
  font-size: 20px
  font-weight: 500
  margin: 0
h6
  font-size: 16px
  font-weight: 500
  margin: 0

a
  text-decoration: none
  color: $color-theme-primary

::placeholder /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $color-placeholder
  opacity: 1 /* Firefox */
  font-weight: 300

:-ms-input-placeholder /* Internet Explorer 10-11 */
  color: $color-placeholder

::-ms-input-placeholder /* Microsoft Edge */
  color: $color-placeholder

.full-page
  width: 100%
  min-height: 100%

.add-staffa-form-container
  height: 100%
  .left
    border-right: 1px solid #B7C5CC

  select
    padding: 12px 8px
    border: 1px solid #D6D9E1
    border-radius: 12px
    font-style: normal
    font-weight: 400
    line-height: 24px
    height: 50px


.half-container
  display: flex
  justify-content: space-between

  .left
    flex-grow: 1
    width: 50%
    padding-right: 24px

  .right
    flex-grow: 1
    width: 50%
    padding-left: 24px

.center
  text-align: center

// External

.ReactModal__Overlay
  display: flex
  align-items: center
  justify-content: center
  z-index: 99

input
  font-size: 14px
  font-style: normal
  font-weight: 400
  line-height: 24px
  padding: 12px
  border: 1px solid rgba(146, 146, 146, 1)
  border-radius: 12px
  width: 100%
  box-sizing: border-box
  &:focus
    border: 1px solid
    border-image-source: radial-gradient(50% 388.06% at 50% 50%, #0054B5 0%, #01B7FF 100%)
  &:focus-visible
    outline: none
  &:active
    border: 1px solid
    border-image-source: radial-gradient(50% 388.06% at 50% 50%, #0054B5 0%, #01B7FF 100%)

select.gray
  background: #EBECF0
  box-sizing: border-box
  padding: 8px 32px 8px 16px
  border: 0
  -webkit-appearance: none
  -moz-appearance: none
  appearance: none
  display: block
  background-image: url(../assets/select-arrow.svg)
  background-repeat: no-repeat
  background-position: center right 16px
  &:focus-visible
    outline: none
.box-white
  padding: 24px
  border-radius: 3px
  background: #FFF
  box-shadow: 0px 3px 5px 0px rgba(9, 30, 66, 0.10)
// Button Style  
.cbtn
  padding: 10px 16px
  border: 0
  display: flex
  align-items: center
  i
    margin-right: 4px
    svg
      display: block
      margin: 0
  span
  font-size: 14px
  font-style: normal
  font-weight: 500
  line-height: 20px
  letter-spacing: -0.084px
  text-transform: capitalize
  &.cbtn-blue
    background: #00B8D9
    color: #fff
  &.cbtn-redius
    border-radius: 3px
  &.cbtn-center
    margin: 0 auto
  &.cbtn-white 
    background: #fff
    color: #344563
    border: 2px solid #DFE1E6
.text-center
  text-align: center
.hide
  display: none !important
.job-box-cta 
    display: flex
    justify-content: flex-start
    margin-top: 24px
    button.cbtn 
      margin-right: 12px
      cursor: pointer !important

::-webkit-scrollbar
  width: 8px
::-webkit-scrollbar-track
  background: #ebecf0
  border-radius: 10px
::-webkit-scrollbar-thumb
  background: #C1C7D0
  border-radius: 10px
::-webkit-scrollbar-thumb:hover
  background: #555

.newLight
  padding: '7px 14px'
  color: '#222647'
  fontSize: '20px'
  fontWeight: '600'
  height: 'auto',
  width: '100%',
  maxWidth: '300px',
  border:'1px solid #222647'
                  
.newDark
  padding: '7px 14px',
  color: '#222647',
  fontSize: '20px',
  fontWeight: '600',
  height: 'auto',
  width: '100%',
  maxWidth: '300px',
  border:'1px solid #222647'

@mixin common-styles
  padding: 7px 25px
  font-size: 20px
  font-weight: 500
  height: auto
  max-width: 300px
  border: 1px solid #222647

.newLight
  @include common-styles
  color: #222647

.newDark
  @include common-styles
  color: #fff
  background: #222647

  &:hover
    color: #fff