.popup-link
  display: flex
  flex-wrap: wrap

.popup-link a
  background: #333
  color: #fff
  padding: 10px 30px
  border-radius: 5px
  font-size: 17px
  cursor: pointer
  margin: 20px
  text-decoration: none

.popup-container
  visibility: hidden
  opacity: 0
  transition: all 0.3s ease-in-out
  transform: scale(1.3)
  position: fixed
  z-index: 1
  left: 0
  top: 0
  width: 100%
  height: 100%
  background-color: rgba(21, 17, 17, 0.61)
  display: flex
  align-items: center

.popup-content
  background-color: #FEFEFE
  margin: auto
  padding: 20px
  border: 1px solid #888
  width: 50%

  p
    font-size: 17px
    padding: 10px
    line-height: 20px

  a.close
    color: #aaaaaa
    float: right
    font-size: 28px
    font-weight: bold
    background: none
    padding: 0
    margin: 0
    text-decoration: none

    &:hover
      color: #333

  span:hover,
  span:focus
    color: #000
    text-decoration: none
    cursor: pointer

.popup-container:target
  visibility: visible
  opacity: 1
  transform: scale(1)

.popup-container h3
  margin: 10px

/* End popup styles */

/* Additional styles */
.popup-style-2
  transform: scale(0.3)

.popup-style-2:target
  transform: scale(1)

.popup-style
    visibility: visible
    opacity: 1
    width: 100%
    z-index: 9999
    position: absolute