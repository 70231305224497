@use "sass:map"


$text-colors: ("slate": #1E293B, "foreground": #5A5F6D, "neutral": #5E6C84)
$primary-colors: ("white": #ffffff, "red": #ED4745)
$theme-colors: ("error": map.get($primary-colors, "red"))

$color-placeholder: #858A99
$color-border: #D6D9E1

// Theme Colors
$color-teal-50: #E6FCFF
$color-teal-300: #00B8D9
$color-teal-500: #008DA6

$color-neutral-0: #FFFFFF
$color-neutral-10: #FAFBFC
$color-neutral-30: #EBECF0
$color-neutral-40: #DFE1E6
$color-neutral-60: #B3BAC5
$color-neutral-70: #A5ADBA
$color-neutral-80: #97A0AF
$color-neutral-300: #5E6C84
$color-neutral-400: #505F79
$color-neutral-500: #42526E
$color-neutral-600: #344563
$color-neutral-700: #091E42
$color-neutral-800: #172B4D
$color-neutral-900: $color-neutral-700
$color-primary-blue: #00314D
$input-Color:#929292

$color-theme-primary: $color-teal-300

$border-radius: 8px 8px 8px 12px
$border-radius-button: 3px 3px 3px 12px

// Font
$font-family: "Barlow Semi Condensed", sans-serif
$font-Onest: 'Onest', sans-serif

// Measurements

$height-header: 64px
