@use "sass:map"
@import 'styles/vars'

.main-container
  padding: 20px
.data-container
  margin: 12px 0px
.heading
  font-size: 20px
  font-weight: 600 
.title
  font-size: 14px
  color: gray
.value
  font-size: 14px
  font-weight: 500