@use "sass:map"
@import 'styles/vars'

.pinned-job-card 
  width: 100% 

.pinned-job-heading
    display: flex
    align-items: center
    justify-content: space-between
	
.pinned-title
  display: flex
  align-items: center
  gap: 5px
  color: #00314D
  font-size: 20px
  font-weight: 600
  line-height: 16px

.shift-button
  color: red
  font-family: SF Pro Text
  font-size: 14px
  font-style: normal
  font-weight: 600
  line-height: 16px

.shift-button button
  border: none
  cursor: pointer
  background: none
  &:hover
    background: #e9f2eb
    border-radius: 20%
  
 




.pinned-job-content
  margin-top:16px
  width: 100%
  padding: 12px
  border-radius:12px
  border: 1px solid #E0E9ED
  background: #FFF
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.04)

.job-detail
  display: flex
  gap: 20px
  flex-direction: row

.pinned-text
  display: flex
  width: 290px
  justify-content: space-between
  align-items: center

.pinned-job-content
  display: flex
  
  .pinned-job-left
    margin-right: 12px

  .progress-badge
    font-size: 12px
    font-weight: 600
    border-radius: 14px
    padding: 5px 10px

  .title 
    color: #00314D
    font-size: 22px
    font-style: normal
    font-weight: 400
    line-height: 16px

.job-progress-status
  display: flex
  gap: 20px
  justify-content: flex-start

.progress-badge
  background: #DEEBFF
  padding: 4px 12px
  border-radius: 3px
  color: #0747A6

.user-info
  display: flex
  flex-direction: column
  align-items: start
  gap: 10px
  margin-top: 23px

.user-detail
  display: flex
  gap: 10px
  align-items: center