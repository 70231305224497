.tab
  float: left

.tab__group
  position: relative
  height: 340px

  &::after
    content: ""
    display: block
    clear: both

.tab__label
  display: inline-block
  padding: 0 20px
  font-size: 16px
  line-height: 40px
  cursor: pointer
  background: #fff

.tab__content
  position: absolute
  width: 100%
  height: 300px
  top: 40px
  left: 0
  right: 0
  padding: 20px
  overflow-x: auto
  background: #fff

.tab__radio
  display: none

  &:not(:checked)
    ~ .tab__label
      background: transparent

    ~ .tab_
