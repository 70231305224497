@use "sass:map"
@import 'styles/vars'


.input-field
  color: map.get($text-colors, "foreground")
  .header
    padding-bottom: 8px
    font-weight: 500

  input,
  textarea
    width: 100%
    box-sizing: border-box
    padding: 12px 8px
    border: 1px solid rgba(146, 146, 146, 1)
    border-radius: 4px !important
    font-family: $font-Onest !important
    font-size: 16px !important
    font-weight: 300
    line-height: 19.2px
    text-align: left
    color:$input-Color
    background: none

    &:focus
      border-image: radial-gradient(50% 388.06% at 50% 50%, #0054B5 0%, #01B7FF 100%)
      border-image-slice: 1
      border: none 
      border-radius: 4px !important
      box-shadow: 0 0 0 1px #0054B5, 0 0 0 2px #01B7FF

    &:active
      border-image: radial-gradient(50% 388.06% at 50% 50%, #0054B5 0%, #01B7FF 100%)
      border-image-slice: 1
      border: none 
      border-radius: 4px !important
      box-shadow: 0 0 0 1px #0054B5, 0 0 0 2px #01B7FF

    &:valid
     background-color: transparent !important /* or set it to any color you prefer */
     border: 1px solid
 
  textarea
    min-height: 85px

  .error
    margin-bottom: 12px
    margin-top: 8px!important
    color: #EB5757!important
    font-size: 14px

.error
  input,
  textarea
    border-color: #EB5757!important
  
  input::-webkit-input-placeholder
    color: #EB5757


.required-indicator
  color: map.get($theme-colors, "error")
  margin-left: 4px

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button 
  -webkit-appearance: none


.input-group-container 
    display: flex
    align-items: center
    position: relative

    span
      display: block
      padding: 0px 20px
      min-width: 85px
      font-weight: 500
      border-radius: 10px
      margin-right: -4px
      position: relative
      z-index: 1
      height: 44px
      display: flex
      align-items: center
      justify-content: center
      position: absolute
      left: 3px
      background: #e5eaed
      color: #00314D

    &.input-cap-space input
      padding-left: 95px

input::placeholder
    font-family: $font-Onest !important
    font-size: 16px !important
    font-weight: 300
    line-height: 19.2px
    text-align: left
    color:$input-Color

