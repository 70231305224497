@import 'styles/vars'

.email-sent
  text-align: center

  .title
    margin-top: 24px
    color: $color-neutral-900
    font-size: 20px
    font-style: normal
    font-weight: 400
    line-height: 24px

  p
    color: $color-neutral-500
    margin-bottom: 24px

    span
      display: block
      margin-top: 1em
      font-weight: bold
