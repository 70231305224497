.nojobdata
    text-align: center
    display: flex
    flex-direction: column
    align-items: center
    width: 100%
    position: absolute
    top: 50%
    transform: translateY(-50%)

.nojobdata h5
  margin-top: 15px
  font-size:32px
  color:#00314D

.nojobdata p
  margin-top: 10px
  font-size:18px
  color:#00314D