.appContainer 
    margin-left: 90px

.wrapperBuildApp 
    margin-bottom: 30px

h6.titleBuild 
    font-size: 24px
    font-weight: 600

p.paraApp 
    font-size: 24px

p.notePara 
    font-size: 20px

ul.ListPoints 
    list-style-position: inside
    font-size: 18px
    margin-left: 20px
    margin-top: 20px
    line-height: 28px

.depositWrapper 
    margin: 30px 0

.depositeRow 
    display: flex
    font-size: 20px
    margin-bottom: 10px

.depositeRow div:nth-child(2) 
    text-align: end


.depositeColumn 
    min-width: 270px

.depositeRow.depositeRowTotal .depositeColumn 
    border-top: 1px solid #000
    margin-top: 20px
    padding-top: 10px

span.sbgTick 
    display: inline-block

.setupPay img 
    width: 20px
    height: auto
    margin-right: 8px

.setupPay 
    display: inline-block
    margin-bottom: 20px


button.pay-button 
    padding: 12.5px 24px
    border-radius: 10px
    border: 0px
    font-size: 18px
    font-weight: 600
    color: #fff
    display: block
    margin-top: 10px
    min-width: 240px


