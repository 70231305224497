@import 'styles/vars'

.email-preview
  .preview-title
    display: inline-flex
    justify-content: center
    align-items: center
    flex-shrink: 0
    margin-bottom: 12px
    background: transparent!important
    color: #00314D!important
    font-size: 20px
    font-weight: 600
    padding: 0!important

  .preview-content
    background-color: $color-neutral-0
    border-radius: 12px
    border: 1px solid #00B8D9
    box-shadow: 0px 20px 30px 0px #00314D26
    min-height: 45px
    font-family: "Barlow Semi Condensed", sans-serif
    padding: 24px
    color: #60747E
    font-size: 16px
    white-space: pre-wrap
    white-space: -moz-pre-wrap
    white-space: -pre-wrap
    white-space: -o-pre-wrap
    word-wrap: break-word
