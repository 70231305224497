@use "sass:map"
@import 'styles/vars'

textarea
  resize: none

@media (max-width: 1660px)
  .jobs.flat .have-pinned-jobs .list > *
    width: calc(100% - 20px)!important

@media (max-width: 1920px)
  .jobs.flat .have-pinned-jobs .list > *
    width: calc(50% - 20px)!important

@media (max-width: 1366px)
  .jobs.flat .list > *
    width: calc(50% - 20px)!important

  .jobs.flat .have-pinned-jobs .list > *
    width: calc(100% - 20px)!important


@media (min-width: 1940px)
  .jobs.flat .list > *
    width: calc(25% - 20px)!important

  .jobs.flat .have-pinned-jobs .list > *
    width: calc(33.33% - 20px)!important


.jobs
  width: 100%
  height: calc(100% - 86px)

  &.flat
    height: 100%!important

    .job-listing
      height: 100%

      .pinned-job
        height: calc(100% - 112px)

        .jobs-container
          height: calc(100% - 20px)
          overflow-y: auto
          overflow-x: hidden

          .pinned-job-card-container
            overflow: auto
            flex-shrink: 0

    .list
      display: flex
      flex-wrap: wrap
      margin: 0 -10px
      margin-top: 10px
      align-content: baseline
      overflow: auto
      width: 100%

      > * 
        width: calc(33.33% - 20px)
        margin: 10px

  .invite-success
    text-align: center

  .jobs-listing
    width: 100%
    height: 100vh
  .pinned-job
    padding-bottom: 30px
  .pending-jobs
    padding-top: 20px
  .active-job-item

  .job-item
    display: flex
    justify-content: space-between
    border-radius: 12px
    border: 1px solid #E0E9ED
    background: $color-neutral-0
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.04)
    padding: 12px
    margin-bottom: 12px

    .progress-badge
      background: #ffd3d3
      color:#a13030
      font-size: 12px
      font-weight: 600
      border-radius: 14px
      padding: 5px 10px



    .left,
    .right
      display: flex
      align-items: flex-start
      flex-shrink: 0

    .details
      margin-left: 17px

      .title
        color: #00314D
        font-size: 22px
        font-style: normal
        font-weight: 500
        line-height: 16px
        margin-bottom: 12px
        margin-right: 10px
        margin-top: 8px

      .job_middle_content
        display: flex
        align-items: center
        gap: 12px

      .info
        color: $color-neutral-400
        display: flex

        img
          margin-right: 6px

.pending-job-detail
  height: 100%
  .proposal-name
    display: flex
    align-items: center
    margin-bottom: 16px

    span.job-name
      color: $color-neutral-900
      font-size: 16px
      font-style: normal
      font-weight: 500
      margin-left: 16px

.ant-select-selector
  background-color: #EBECF0 !important
  border-color: #EBECF0 !important
  height: 38px !important

.ant-select-selector:hover
  background: #EBECF0 !important
  border-color: #EBECF0 !important

.ant-select-selection-item
  padding: 2px !important

.sort-title
  display: flex
  margin-top: 12
  text-align: right
  font-style: normal
  font-weight: 600
  line-height: 16px
  margin-right: 10px
  margin-right: 10px
  font-size: 18px
  color: #265068

.job-wrapper
  display: flex
  padding-bottom: 4px
  justify-content: space-between
  flex-flow: row wrap
  height: 85%
  overflow: hidden
  @media screen and ( max-width:991px  )
    display: block
  .job-wrapper-left
    width: calc( 70% - 30px )
    height: 100%
    overflow-y: auto
    padding-right: 23px
    @media screen and ( max-width:991px  )
      width: 100%
  .Sidebar
    box-shadow: 0px 3px 5px 0px rgba(9, 30, 66, 0.10)
    padding: 24px
  .job-box
    width: 100%
    @media screen and ( max-width:991px  )
      width: 100%
      margin-bottom: 24px
    
    > *
      padding: 20px
      border: 1px solid #E0E9ED
      background: #fff
      border-radius: 12px
      box-shadow: 0px 20px 30px 0px #00314D0D

    .job-box-title
      display: flex
      margin-bottom: 32px
      .job-box-title-left
        width: 70%
        display: flex
        align-items: center
        .user-pic
          margin-right: 16px
        .user-info
          h2
            margin: 0 0 3px !important
            padding: 0
            display: block
            font-size: 20px
            font-style: normal
            font-weight: 500
            line-height: 24px
          span
            margin-top: -10px
      .job-box-title-right
        width: 30%
        display: flex
        align-items: center
        justify-content: flex-end
  .special-notes
    h3
      font-size: 16px
      font-style: normal
      font-weight: 600
      line-height: 20px
      margin-bottom: 12px
    p
      font-size: 14px
      font-style: normal
      font-weight: 400
      line-height: 20px
      margin: 0 !important
      overflow: visible
      text-overflow: none
  .job-activity
    width: calc( 30% - 30px )
    height: 100%
    overflow-y: auto
    padding-right: 23px
    @media screen and ( max-width:991px  )
      width: 100%
      margin-bottom: 48px
    textarea
      font-size: 14px
      font-style: normal
      font-weight: 400
      line-height: 24px
      padding: 12px
      border: 1px solid #D6D9E1
      border-radius: 4px
      width: 100%
      box-sizing: border-box
      height: 200px
      margin-bottom: 12px
    .btn-wrapper
      display: flex
      align-items: center
      justify-content: space-between
      gap: 20px
      .chooseFile-wrapper
        position: relative
        overflow: hidden
        display: inline-block
        #chooseFile
          position: absolute
          left: 0
          top: 0
          opacity: 0
      .submit-cancel-btns
        display: flex
        align-items: center
        gap: 12px
    h2
      margin-bottom: 16px
    hr
      border: 0
      border-top: 1px solid #DFE1E6
      margin-top: 16px
    .error
      margin-bottom: 12px
      color: map.get($theme-colors, "error")
  .job-task
    width: 100%
    margin-top: 10px

    @media screen and ( max-width:991px  )
      width: 100%
    h2
      font-size: 20px
      font-style: normal
      font-weight: 600
      line-height: 24px
      margin-bottom: 12px
      color: #00314D

    .job-tasks-search
      position: relative

      input 
        padding-left: 55px
        border-color: #B7C5CC!important

      .search-icon
        position: absolute
        top: 25px
        left: 25px

    .loader-wrapper
      margin-top: 10px
      margin-bottom: 15px
    


      .percentage-text
        color: #00B8D9
        font-size: 16px
        font-weight: 600
        padding: 0
        margin-bottom: 10px
     
      .loader-bar
        width: 100%
        span
          border-radius: 10px
          height: 5px
          display: block
          background: #DFE1E6
      span
        font-size: 14px
        font-style: normal
        font-weight: 500
        line-height: 14px
        letter-spacing: -0.28px
        display: block
        white-space: nowrap
        padding-left: 8px
        box-sizing: border-box
    .job-tasks-box
      .nofound
        font-size: 20px
        font-style: normal
        font-weight: 500
        line-height: 24px
        display: block
        text-align: center
        padding: 12px 15px 36px
        box-sizing: border-box



.jobs-list
  margin-top: 50px
  .job-item-list
    position: relative
    padding-left: 50px
    margin-bottom: 40px
    .user-icon
      background-color: #FF991F
      width: 40px
      height: 40px
      display: flex
      align-items: center
      justify-content: center
      border-radius: 50px
      font-size: 16px
      font-style: normal
      font-weight: 600
      line-height: 20px
      color: #fff
      position: absolute
      left: 0
      top: 0
    .user-details
      h6
        font-size: 14px
        font-style: normal
        font-weight: 600
        line-height: 16px
        margin-bottom: 6px
      p
        font-size: 14px
        font-style: normal
        font-weight: 400
        line-height: 20px
    .user-inner
      display: flex
      justify-content: space-between
      align-items: center
    .user-inner-right
      .kebab-menu
        .kebab-menu-list
          li
            padding: 6px 12px !important
            width: auto !important
            display: flex
            align-items: center
            cursor: pointer

.history-deleted
  width: 100%
  max-width: 350px
  display: block
  margin-left: auto

.atteched-files
  display: flex
  flex-wrap: wrap
  justify-content: start
  gap: 8px
  .file-img
    position: relative
  .file-img img
    width: 100%
  .file-img button
    background-color: #fff
    border-radius: 50px
    width: 32px
    height: 32px
    border: none
    position: absolute
    right: 5px
    top: 5px
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer
  .atteched-file-item
    width: calc(33.33% - 8px)
    background-color: #fff
    .file-name
      background-color: #fff
      font-size: 14px
      font-style: normal
      font-weight: 500
      line-height: 20px
      letter-spacing: -0.084px
      display: inline-block
      padding: 3px 8px
      text-align: center
      width: 100%
      box-sizing: border-box

.attch-img-wrapper
  display: flex
  flex-flow: row wrap
  margin-bottom: 20px
  padding-top: 12px
  .attachted-imgs
    display: flex
    flex-direction: column
    position: relative
    width: 33.33%
    max-width: 120px
    margin-bottom: 16px
    &:not(:last-child)
      margin-right: 24px
    i
      position: absolute
      top: -12px
      right: -12px
      width: 24px
      height: 24px
      &:hover
        cursor: pointer
    img
      margin-bottom: 4px
      display: block
      width: 100%
    span
      padding: 7px 16px
      font-size: 14px
      font-style: normal
      font-weight: 500
      line-height: 20px
      letter-spacing: -0.084px
      border-radius: 3px
      border: 1px solid #DFE1E6
      text-align: center
      overflow: hidden
      text-overflow: ellipsis

.sort-titles
  margin-right: 10px
  margin-top: 12px
  margin-bottom: 10px
  font-size: 16px
  color: #00314D
  font-style: normal
  font-weight: 600
  margin-right: 10px

.gray
  margin-top: 10px

.files
  display: flex
  justify-content: space-between
  padding: 8px
  width: 100% !important
  border: 2px solid #DFE1E6
  cursor: pointer

.uploadFile
  display: flex
  justify-content: space-between
  cursor: pointer
  padding: 5px
  height: 16px
  width: 90px
  background-color: #DFE1E6
  margin-top: 10px

.report
  margin-top: 10px
  display: flex
  align-items: center
  gap: 12px
  font-size: 18px
  font-weight: 500
  color: #00314D

.details-container
  margin: 20px 0px
  cursor: pointer

.details-container-item
  margin: 5px 0px


.cta-btn
  display: flex
  justify-content: center
  align-items: center
  gap: 5px
  margin-top: 10px
  background-color: #EBECF0
  height: 30px
  width: 120px
  padding: 4px
  cursor: pointer

.cta-contents
  margin-bottom: 1000px

.user-info
  h2
    margin: 0 0 8px !important
    padding: 0
    display: block
    font-size: 20px
    margin-bottom: 20px
    font-style: normal
    font-weight: 500
    line-height: 24px
    span
      margin-top: -10px

.cta-btn
  i
    margin-bottom: 100px

.btn
  display: flex
  justify-content: space-between
  padding: 10px
  margin-top: 30px
  background: #FFF
  border-top: 1px solid grey

.cnl-btn
  border: none
  padding: 8px
  border-radius: 4px
  cursor: pointer

.sub-btn
  border: none
  padding: 8px
  background: #00B8D9
  color: white
  border-radius: 4px
  cursor: pointer

.cbtn-text
  cursor: pointer

.Priority-bullet
  height: 10px
  width: 10px
  border-radius: 50%
  display: inline-block
  margin-right: 10px
  text-align: center
  margin-top: 5px

.sub-btn-edit
  border: none
  padding: 8px
  background: #00B8D9
  display: flex
  justify-content: flex-end
  color: white
  border-radius: 4px
  cursor: pointer

.btn-save
  display: flex
  justify-content:flex-end
  padding:4px
  margin-right:20px
  margin-top:10px

.addTask-wrapper
  display: flex
  justify-content: space-between
  z-index: -1
  overflow: hidden
  height: 100%

.addTask-wrapper-left
  width: 60%
  padding-right: 20px
  overflow-y: auto
  border-right: 1px solid #B7C5CC
  height: calc(100% - 60px)
  
  .job-box
    position: relative
    border-radius: 3px
    background: #FFF
    box-sizing: border-box
    margin-bottom: 20px
    width: 100%
    @media screen and ( max-width:991px  )
      width: 100%
      margin-bottom: 24px
    .show_loader
      position: absolute
      top: 50%
      right: 40%
      transform: translate(-50%, -50%)
    .job-box-title
      display: flex
      margin-bottom: 32px
      .job-box-title-left
        width: 70%
        display: flex
        align-items: center
        .user-pic
          margin-right: 16px
        .user-info
          h2
            margin: 0 0 3px !important
            padding: 0
            display: block
            font-size: 20px
            font-style: normal
            font-weight: 500
            line-height: 24px
          span
            margin-top: -10px
      .job-box-title-right
        width: 30%
        display: flex
        align-items: center
        justify-content: flex-end

.addTask-status
  width: 40%
  padding: 15px
  padding-bottom: 30px
  background: #FFF
  height: fit-content

.show_loader
  display: flex
  justify-content: center
 
.customers-apps-pending-tasks
  
  .right
    flex-wrap: wrap
    margin-left: 0!important
    margin-top: 10px      


body .user-info
    
  span
    font-size: 14px
    font-weight: 500
    color: #265068

.job-wrapper .job-box .job-box-title .job-box-title-left .user-info h2
  font-size: 22px
  margin-bottom: 10px!important
  color: #00314D

.job-box-cta
  
  .cbtn
    background-color: #E0E9ED!important
    border-radius: 12px!important
    padding: 12px 24px 12px 24px!important
    border: none!important
    color: #265068!important
    display: flex
    align-items: center
    font-size: 18px
    font-weight: 600

.job-task-list
  display: flex
  flex-wrap: wrap
  margin: 0 -10px

  .job-task-item
    border: 1px solid #B7C5CC
    border-radius: 12px
    width: calc(25% - 20px)
    margin: 10px 10px
    padding: 15px

    @media (max-width: 1366px)
      width: calc(33.33% - 20px)
   
    @media (min-width: 1921px)
      width: calc(20% - 20px)

    .Priority-bullet
      width: 16px
      height: 16px
      border-radius: 4px

    h6
      font-size: 18px
      font-weight: 500
      color: #00314D
      display: -webkit-box
      -webkit-line-clamp: 4
      -webkit-box-orient: vertical
      text-overflow: ellipsis
      overflow: hidden
      min-height: 90px
      
    .task-status-bar
      padding: 8px 16px
      display: flex
      align-items: center
      justify-content: center
      width: 100%
      margin-top: 10px
      text-transform: capitalize
      font-weight: 500
      font-size: 16px

.jobs-container
  display: flex

  .pinned-job-card-container
    max-width: 400px
    width: 100%
    padding-left: 20px
    margin-left: 20px
    border-left: 1px solid #B7C5CC

.cart-bottom-info
  display: flex
  align-items: center
  margin-bottom: 14px
  gap: 10px

  .cart-bottom-info-item
    display: flex
    align-items: center
    gap: 5px
    color: #60747E
    font-size: 14px

.header-button-container
  display: flex
  gap: 12px

  .sub-btn
    padding: 12px 24px
    background: #00B8D9
    border-radius: 12px
    font-size: 18px
    font-weight: 500

  .cnl-btn
    padding: 12px 24px
    background: #E0E9ED
    color: #265068
    border-radius: 12px
    font-size: 18px
    font-weight: 500

.pending-job-detail
  label
    color: #00314D
    font-size: 16px
    font-weight: 600

    margin-top: 8px
    margin-bottom: 8px
    display: inline-block

.upload_btn
  padding: 24px 20px
  border: 1px solid #00B8D9
  width: 100%
  background: transparent
  border-radius: 16px
  display: flex
  flex-direction: column
  height: auto
  color: #00314D
  font-size: 16px
  font-weight: 600
  gap: 12px

.user-infos
  h2
    font-size: 20px
    font-weight: 500
    color: #00314D
  
  span
    font-size: 12px
    font-weight: 400
    color: #265068
    