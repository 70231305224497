@import 'styles/vars'
*
    margin: 0
    padding: 0
    box-sizing: border-box
    font-family: "Barlow Semi Condensed", sans-serif

.page-title
    font-size: 40px
    font-weight: 600
    color: $color-primary-blue

.page-sub-text
    font-size: 16px
    color: #60747E

.horizontal-columns-jobs
    width: 100%
    // display: flex // Uncomment to enable flex display
    // flex-direction: row // Uncomment to set the direction of flex items
    height: 100%

    .column-job:not(:last-child)
        // border-right: 1px solid #B7C5CC // Uncomment to add border between jobs

    .column-job 
        flex-shrink: 0
        // width: 33.33% // Uncomment to set fixed width for columns
        overflow: hidden
        padding: 0 20px

        .column-body 
            display: flex
            gap: 20px
            flex-wrap: wrap

            .new-job-item
                width: 100%
                min-width: 420px
                max-width: 450px
    
.column-header
    padding-bottom:10px
    position: relative
    margin-bottom: 10px
    display: flex
    align-items: center
    position: sticky
    top: 0
    background: white

    .column-heading
        font-size: 18px
        color: #80949D
        font-weight: 600
        

.new-job-item 
    display: flex
    align-items: flex-start
    justify-content: space-between
    border-radius: 12px
    border: 1px solid #E0E9ED
    background: #FFFFFF
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.04)
    padding: 12px
    margin-bottom: 12px

    .title
        color: #00314D
        font-size: 22px
        font-style: normal
        font-weight: 500
        line-height: 16px
        margin-bottom: 12px
        margin-top: 8px
        margin-right: 10px
        text-transform: capitalize
    
    .job_middle_content
        display: flex
        gap: 12px
        align-items: center

        .description 
            color: #265068
            font-weight: 500
    
        .username
            padding: 0
            margin:0
            color:#265068

        .job_status
            background: #ffd3d3
            color:#a13030
            font-size: 12px
            font-weight: 600
            border-radius: 14px
            padding: 5px 10px

    .new-job-left
        width:100%
        display: flex
        gap:12px

        .new_job_image
            width:54px
            height:54px
            border-radius:50%
            display: flex
            align-items: center
            justify-content: center
            overflow: hidden

            img
                width: 100%
                height: 100%
                object-fit: fit-content

        .business
            margin-bottom: 14px

    .new-job-right 
        display: flex
        justify-content: space-between
        align-items: center
        margin-top: 8px
        
        .new-job-right-inner
            display: flex
            align-items: center
            justify-content: center

        button
            margin-left: 10px
        
.button_div
    display: flex
    flex-direction: row
    justify-content: center
    gap:15px

.inactive-jobs-info
    background: white
    border-radius: 5px
    margin-left: 100px
        
.payment-modal-main
    padding: 20px

.kebab-menu button
    transform: rotate(0deg)
    border: none
    padding: 0
    margin: 0

.jobs-container
    width: 100%
    .list
        width: 100%

// Define loader styles
.loader
    border: 4px solid #f3f3f3 // Light bluish color
    border-top: 4px solid #3498db // Dark bluish color
    border-radius: 50%
    width: 30px
    height: 30px
    animation: spin 1s linear infinite // Apply rotation animation
    position: absolute
    top: 50%
    left: 50%
    margin-top: -15px // Half of the loader height
    margin-left: -15px // Half of the loader width

@keyframes spin
    0% 
        transform: rotate(0deg)
    100% 
        transform: rotate(360deg)        