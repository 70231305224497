@import 'styles/vars'
.ReactModalPortal
  z-index: 999
  position: relative

.base-modal.subscriptionModal
  max-width: 1200px!important
  width: 100%!important

.base-modal.paymentModal
  max-width: 888px!important
  width: 100%!important

  .credit-card-form 
    max-height: 100%

.base-modal
  width: 588px
  border-radius: 8px
  overflow: hidden
  background: $color-neutral-0
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.12)

  .modal-header
    padding: 24px

    .subtitle
      color: #2F3441
      margin-top: 16px

  .close-button
    float: right
    padding: 0

    img
      width: 24px
      height: 24px
      padding: 24px

  .modal-body
    margin: 24px
    margin-top: 0
    padding: 16px
    border-radius: 4px
    border: 1px solid #F1F3F8
    background: $color-neutral-0
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.04)

  .modal-footer
    padding: 16px 24px
    text-align: right
    background-color: $color-neutral-10
    border-top: 1px solid $color-neutral-30

  .delete-email-template
    .title
      color: $color-neutral-900

    .subtitle
      color: $color-neutral-400
      margin-top: 9px

  .delete-app-template
    display: flex
    align-items: center

    .avatar
      margin-right: 16px
      width: 56px
      height: 56px

    .title
      color: $color-neutral-900

    .subtitle
      color: $color-neutral-400
      margin-top: 9px

.modalPrev
  .JobName
    font-size: 20px
    font-weight: 500

.subscription-modal-main
  padding: 24px
  border-radius: 12px

  .subs-content
    margin-top: 20px
    p
      color: #60747E
      font-size: 18px
      margin-top: 12px
      font-weight: 400

.subs-packages-container
  display: flex
  margin: 0 -8px
  margin-top: 40px
  justify-content: center

  .subs-package
    width: calc(33.33% - 16px)
    margin: 0 8px
    box-shadow: 0px 20px 30px 0px #00B8D90D
    padding: 24px
    padding-top: 40px
    border: 1px solid #E0E9ED
    border-radius: 12px
    transition: all ease-in-out 0.4s
    position: relative
    overflow: hidden
    display: flex
    flex-direction: column
    justify-content: space-between

    .popular-tag
      width: 200px
      background: #EB5757
      height: 30px
      display: flex
      align-items: center
      justify-content: center
      color: white
      font-size: 16px
      font-weight: 600
      position: absolute
      top: -32px
      right: -80px
      transform-origin: left
      transform: rotate(45deg)

    &:hover
      transform: scale(1.05)

    &.active
      border-color: #00B8D9

      .subs-btn
        background: #00B8D9
        color: white

    .packages-title
      text-align: center
      font-size: 28px
      color: #00314D

    .package-price
      text-align: center
      margin-top: 16px
      margin-bottom: 40px

      .main-price
        font-size: 40px
        font-weight: 600
        color: #00314D
      
      .currency-sign
        font-size: 20px
        color: #60747E
        font-weight: 600
        position: relative
        top: -12px
        left: -2px
        display: inline-block
      
      .package-duration
        color: #60747E
        font-size: 20px

  .package-features-list
    display: flex
    flex-direction: column

    .package-feature-item
      display: flex
      gap: 12px
      align-items: center
      margin-bottom: 10px

      .item-icon
        height: 24px
      
      .item-title
        color: #60747E
        font-size: 18px
    
  .subs-btn
    padding: 12px 24px
    width: 100%
    border-radius: 12px
    border: none
    background: #E0E9ED
    margin-top: 20px
    color: #265068
    font-size: 18px
    font-weight: 600
    cursor: pointer
    transition: all ease-in-out 0.4s

    &:hover
      background: #00B8D9
      color: white
    
