@import 'styles/vars'

.modalPrev
  display: flex
  align-items: center
  gap: 20px

  .details
        margin-left: 16px

  .name
      font-weight: 500

  .bottom
      color: $color-neutral-400
      display: flex
      align-items: center
      margin-top: 8px
      
  .mid-dot
      color: $color-neutral-60
      margin: 0 7px
      font-size: 30px
      line-height: 1

.users
  height: 100%
  width: 100%
  display: flex
  flex-direction: column

  .page-header
    margin-top: 0

  .customer-content
    height: 100%!important
    
    .list
      height: 100%
      width: 100%
      padding-bottom: 20px

.users .no-data
  width: 100%
  display: flex
  justify-content: center
  align-items: center
  height: 100%

.staff
  width: 100%
  height: 100%

  .left,
  .right
    display: flex
    align-items: flex-start

  .staff-listing
    height: 100vh
    padding-top:0px
    padding-bottom:50px

    .list 
      padding-bottom: 10px

    .staff-item
      background: #FFF
      display: flex
      margin-bottom: 20px
      box-shadow: 0px 20px 30px 0px rgba(0, 49, 77, 0.0509803922)
      border: 1px solid #E0E9ED
      border-radius: 12px
      padding: 12px 12px 24px 24px
      align-items: flex-start

      .left
        align-items: flex-start !important

      .details
        margin-left: 16px

      .name
        color: #00314D
        font-size: 22px
        margin-top: 0
        font-weight: 400

      .bottom
        color: $color-neutral-400
        display: flex
        align-items: center
        margin-top: 8px

      .email
        color: #265068
        font-size: 14px
        font-weight: 500
        
      .progress-badge
        background: #ffd3d3
        color:#a13030
        font-size: 12px
        font-weight: 600
        border-radius: 14px
        padding: 5px 10px
        text-transform: capitalize

      .date
        color: #60747E
        font-size: 14px
        font-weight: 400 

    .mid-dot
      color: $color-neutral-60
      margin: 0 7px
      font-size: 30px
      line-height: 1
    
    .sort-title
      margin-right:10px
      font-size: 18px
      color: #265068

.container .body .detail 
  align-items: flex-start

.role-badge
  border-radius: 40px
  background-color: $color-neutral-30
  color: $color-neutral-600
  padding: 4px 12px
  display: inline-block
  line-height: 20px

  &.teal
    color: $color-teal-500
    background-color: $color-teal-50

.bottom 
  display: flex
  align-items: center
  gap: 8px

.details .name
  margin-bottom:8px