@import 'styles/vars'

.buttons-container
  display: flex
  align-items: center
  width: 100%!important
  margin-top: 20px
  gap: 12px
  &.left
    justify-content: flex-start
  &.center
    justify-content: center
  &.right
    justify-content: flex-end


.button
  background: none
  border: 0
  cursor: pointer
  height: 40px
  border-radius: 3px
  text-align: center
  padding: 0 16px
  display: flex
  align-items: center
  justify-content: center

  &.medium-btn
    padding:12px 24px!important
    font-size: 18px!important
    font-weight: 600
    height: auto!important
    &.cancel-btn
      background: #E0E9ED
      color: #265068!important

  svg
    margin-right: 5px

  &.primary
    background-color: $color-theme-primary
    color: $color-neutral-900

  &.secondary
    background-color: $color-neutral-30
    color: $color-neutral-600

  &.fullwidth
    width: 100%
  
  &.new
    border-radius: 12px
    font-size: 18px
    font-weight: 600
    color: white
    height: auto
    padding: 13px 20px

    &.small
      font-size: 12px
      font-weight: bold
      padding: 6px 10px
      height: auto
      border-radius: 8px
