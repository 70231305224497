@import 'styles/vars'

.create-job-form-container
  // display: flex
  // justify-content: space-between

  .buttons-container
    text-align: right

  // .left
  //   flex-grow: 1
  //   width: 50%
  //   padding-right: 24px

  // .right
  //   flex-grow: 1
  //   width: 50%
  //   padding-left: 24px
