@use "sass:map"
@import 'styles/vars'

.login
  background-color: #000B14
  // height: 100dvh
  position: relative
  width:100%
  overflow-y: hidden
  overflow-x: hidden
  height: 100%
  
  .loginWrapper
    position: relative
    display: flex
    padding-top: 0px
    height: 100%
    overflow-y: auto
    

  img.backgroundLogin 
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    width: 100%
    height: 100%


  .login-left
    display: flex
    align-items: end
    justify-content: end
    z-index: 1
    
   
   
    width: 100%
  
  .login-right
    z-index: 1
    max-width: 60%
    width: 100%
    padding-top:0px
    padding-left:106px



  h1
    color: $color-neutral-0
    font-weight: 600
    line-height: 68.85px
    text-align: left
    font-family: $font-Onest

  
  p
    font-size: 18px
    color: #60747E

  form
    margin-top: 20px
   
    
    label
      font-size: 16px
      font-weight: 400
      color: $color-neutral-0
      padding: 0px
      font-family: $font-Onest
      line-height: 19.2px
      text-align: left


    .forgot-pass
      position: absolute
      top: 0px
      right: 0px
      font-size: 16px
      color: #00B8D9
      text-decoration: underline
      

.login-logo
  text-align: left
  width:100%
  margin-bottom: 20px
  img
    width: auto
  
  a
    display: inline-block

.login-container
  max-width: 520px
  width: 100%
  height: 100%
  display: flex
  align-items: center
  flex-direction: column
  justify-content: center
  margin: auto

.login-content
  // background-color: map.get($primary-colors, "white")
  min-height: 100px
  width: 100%
  box-sizing: border-box
  border-radius: $border-radius
 

.error
  color: red

.generate_pass_div 
  button.primary.button
    background-color: #fff
    color: #00B8D9
    border: 1px solid
    padding: 0

.login-left img 
  height: 93%
  width: max-content
  margin-left: auto

  
  
.form_inner 
  margin-top: 30px

.input-field .header 
  padding-bottom: 8px
  font-weight: 600
  font-size: 16px
  color: #00314D

.form_inner input 
  width: 100%
  box-sizing: border-box
  padding: 12px 8px
  border: 1px solid #D6D9E1
  border-radius: 12px
  outline: none
  margin: 0px 0 10px 0




.buttons-container button 
  background-color: #00B8D9
  border-radius: 12px
  color: #FFFFFF !important
  font-size: 20px
  font-weight: 600

.buttons-container button.primary.button 
  display: flex
  gap: 10px
  align-items: center



.primary.button
  color: white
  font-weight: 600
  height: 56px
  border-radius: 12px
  background: radial-gradient(50% 388.06% at 50% 50%, #0054B5 0%, #01B7FF 100%)
  font-family: $font-Onest
  font-size: 18px
  line-height: 21.6px
  text-align: center
  border-radius: 4px

  &:focus
    background: #0054B5
    outline: none

  &:active
    opacity: 0.5
    background: radial-gradient(50% 388.06% at 50% 50%, #0054B5 0%, #01B7FF 100%)
    border: 1px solid rgba(237, 237, 237, 1)


  &:disabled
    background: radial-gradient(50% 388.06% at 50% 50%, #0054B5 0%, #01B7FF 100%)
    opacity: 0.5
    cursor: not-allowed


.success-container 
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%!important
  background: white

  a
    max-width: 400px


   
.input-group-container span 
    background: red
    right: 0
    left: unset
    background: none
    padding: 0 !important
    min-width: 70px
    cursor: pointer

.input-group-container.input-cap-space input 
    padding-left: 15px

label.cr-wrapper 
    display: flex
    justify-content: start
    align-items: flex-start
    gap: 10px
    margin-top: 60px

    input 
      width: 16px
      height: 16px !important
    span 
      font-family: $font-Onest
      font-size: 16px
      font-weight: 400
      line-height: 19.2px
      text-align: left

.cr-wrapper .input-field .header
  display: none


button.primary.button.fullwidth.googlebtn 
    background: none
    border: 1px solid #01B7FF
    margin-top: 20px
    display: flex
    align-items: center
    gap: 20px
    color: #01B7FF
    line-height: 21.6px
    font-size: 18px
    font-weight: 600
    position: relative

p.sinupLabel 
    font-family: $font-Onest
    font-size: 16px
    font-weight: 400
    line-height: 19.2px
    text-align: left
    margin-top: 20px
    text-align: center
    color:#fff

.sinupLabel a 
    background: radial-gradient(circle farthest-corner at center center, #E04D00 0%, #FF9112 100%)
    -webkit-background-clip: text
    -webkit-text-fill-color: transparent
    font-family: $font-Onest
    font-size: 16px
    font-weight: 400
    line-height: 19.2px
    text-align: left

.login-recovery h3
    color: $color-neutral-0
    font-weight: 600
    line-height: 68.85px
    text-align: left
    font-family: $font-Onest
    font-size:54px

.form_header h3
    color: $color-neutral-0
    font-weight: 600
    line-height: 68.85px
    text-align: left
    font-family: $font-Onest
    font-size:54px


@media (max-width: 767px)
  .generate_pass_div
    flex-direction: column
    max-width: 100% !important

    button.primary.button
      width: 100% !important

    button.primary.button.copy_btn
      margin-left: 0 !important
