@use "sass:map"
@import 'styles/vars'

.dashboard
  display: flex
  flex-direction: column
  height: 100% !important
	
  .pinnedList
    margin-top: 0px !important

.no-data
  text-align: center
  margin-top: 0 !important
  height: 100%				

.inner_job_box
  flex-direction: column

.job_box_2
  display: flex
  gap:20px

@media (max-width: 768px)
  .pinnedList .pinned-job-card
    margin-top: 0px
