@use "sass:map"
@import 'styles/vars'

.dashboard
	width: 100%
	height: 100vh
	
	
	
	.pinnedList
		margin-top: 80px


.no-data
	display: flex
	justify-content: center
	align-items: center
	flex-direction: column

.job_content_wrap
	display: flex
	margin-top: 30px
	gap: 20px
	padding-bottom: 100px

.job_box 
	width: 50%
	display: flex
	gap: 20px
	

.head_bg_green 
	background: #3bb380
	height: 45px
	margin-bottom: 30px

.head_bg_blue 
	background: #2688fb
	height: 45px
	margin-bottom: 30px

.head_bg_darkblue
	background: #091e42
	height: 45px
	margin-bottom: 30px
	color: #fff
	display: flex
	justify-content: start
	align-items: center
	padding-left: 15px
	margin-bottom: 0px

.job_box_inner 
	width: 100%
	list-style: none
	padding-left: 0px
	box-shadow: -1px 6px 27px -5px rgba(0,0,0,0.3)
	padding-bottom: 15px
	margin-top: 0px

	.box_li 
		display: flex
		justify-content: space-between
		padding: 5px 15px


		.big_text 
			font-size: 18px
			font-weight: 500



	.user_div
		background: #fff
		box-shadow: -1px 6px 27px -5px rgba(0,0,0,0.3)
		border-radius: 50px
		padding: 15px 20px
		position: relative
		top: 15px
		left: 10px
		font-size: 18px	
		width: 15px
		height: 25px
		display: flex
		justify-content: center
		align-items: center	

	.user_div img
		width: 25px
		height: 25px


	.user_div2
		background: #e7a61a
		box-shadow: -1px 6px 27px -5px rgba(0,0,0,0.3)
		border-radius: 50px
		padding: 15px 20px
		font-size: 18px	
		width: 20px
		height: 30px
		display: flex
		justify-content: center
		align-items: center
		

	.flex_wrap
		display: flex
		gap: 10px

	.mt0
		margin: 0px

	.mt10
		margin-top: 10px

	.pl100
		padding-left: 85px 

	.pt0 
		padding: 0px

	.box_img
		width: 100px
		height: 100px
		background: #ddd
	h4
		font-size: 16px

.box_height 
	height: 190px
	margin-bottom: 0px

.dots_icon img
	width: 30px

.close_icon img
	width: 20px

.upload_img
	position: relative

.close_icon
	position: absolute
	top: 5px
	right: 5px
	padding: 5px
	background: #fff
	width: 20px
	margin-left: auto
	border-radius: 50px
	height: 20px
	display: flex
	justify-content: center
	align-items: center

.box_img
	display: flex
	justify-content: center
	align-items: center

	img
		width: 15px
		height: 15px

.img_text
	text-align: center

.inner_job_box
	display: flex
	width: 100%
	gap: 20px

.flex_wrap2
	flex-wrap: wrap

.flex
	display: flex
	gap: 10px

.new_colur
	background: #ddd
	width: 15px
	height: 15px
	border-radius: 50px

.pro_c 
	background: #2688fb

.org
	background: #fe8b29

.green1
	background: #3db481

.red 
	background: #fe5e44

.w100 
	width: 100%

.align_items
	align-items: center

.pl20
	padding-left: 20px



@media (max-width: 1099px)
	.job_content_wrap
		flex-wrap: wrap

		.job_box
			width: 100%

@media (max-width: 767px)
	.box_height
		height: 210px
        
    


