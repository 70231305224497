@import 'styles/vars'

.kebab-menu
  display: flex
  align-items: center
  position: relative
  
  button
    width: 40px
    height: 40px
    border-radius: 50%
    border: 1px solid #505F79
    display: flex
    align-items: center
    justify-content: center
    transform: rotate(90deg)
    margin-left: 15px

  .kebab-menu-list
    position: absolute
    right: 0px
    top: 45px
    z-index: 10
    margin: 0
    padding: 0
    list-style: none
    background-color: none
    border-radius: 10px
    overflow: hidden
    border: 0px solid var(--foreground-background-2, #F1F3F8)
    // box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.08)
    box-shadow: 0px 10px 11px -2px #00000042


    li
      width: 262px
      padding: 12px 16px
      display: flex
      align-items: center
      cursor: pointer
      background-color: $color-neutral-0

      span
        font-size: 18px
        font-weight: 600
        color: #60747E

      &:hover
        background-color: #E1FAFF

        span
          color: #00B8D9

      img
        margin-right: 20px
        width: 24px
        height: 24px

.dropdownTri 
    height: 12px
    position: relative
    z-index: 1

  
    img.arrowstep 
      width: 30px
      height: 13px
      margin-top: 0px
      position: absolute
      right: 25px
      filter: drop-shadow(0px -1px 1px rgba(0, 0, 0, 0.1215686275))

.kebab-menu .kebab-menu-list >li:nth-child(2) 
    box-shadow: -3px 0px 15px -2px rgba(0, 0, 0, 0.2588235294)
    border-top-left-radius: 10px
    border-top-right-radius: 10px

   
