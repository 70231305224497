.searchBar 
  width: 100%
  display: flex
  flex-direction: row
  align-items: center
  max-width:400px

#searchQueryInput 
  width: 100%
  height: 2.8rem
  outline: none
  border: 1px solid #ccc
  padding: 0 3.5rem 0  3rem
  font-size: 1rem

#searchQuerySubmit
  width: 3.5rem
  height: 2.8rem
  margin-right: -3.5rem
  background: none
  border: none
  outline: none
  z-index:9
  line-height: 0

#searchQuerySubmit:hover
  cursor: pointer