@import 'styles/vars'

@media (max-width: 1660px)
  .subscription-card-item
    width: calc(100% - 20px)!important

@media (max-width: 1939px)
  .subscription-card-item
    width: calc(50% - 20px)!important

@media (max-width: 1366px)
  .subscription-card-item
    width: calc(50% - 20px)!important

@media (min-width: 1940px)
  .subscription-card-item
    width: calc(25% - 20px)!important


.subscription-header
  display: flex
  justify-content: space-between
  margin-bottom: 20px




.subscription-main
  display: flex
  height: calc(100% - 90px)

  .subscription-left
    width: 70%
    border-right: 1px solid #B7C5CC
    padding-right: 24px
    overflow: auto

    .subscription-cards-list
      display: flex
      flex-wrap: wrap
      margin: 0 -10px

      .subscription-card-item
        box-shadow: 0px 20px 30px 0px #00314D0D
        border: 1px solid #E0E9ED
        padding: 24px
        border-radius: 12px
        display: flex
        justify-content: space-between
        margin: 10px

        .subscription-card-content
          display: flex
          align-items: center
          
          & .icon
            width: 56px
            height: 56px
            margin-bottom: 0
            margin-right: 12px

          & .icon img
            width: 56px
            height: 56px
            object-fit: contain

          & .title
            color: #00314D
            font-weight: 400
            font-size: 22px
            margin-bottom: 4px

          & .description 
            color: #60747E
            font-weight: 400
            font-size: 16px

            .currency
              color: #265068
              font-weight: 500

  .subscription-right
    width: 30%
    margin-left: 24px

    
    .email-preview, .preview-content
      width: 100%

.features-item
  margin-bottom: 16px

.feature-dropdown
  position: absolute
  top: 102%
  background: #e5eaed
  list-style: none
  width: 90px
  padding: 9px
  border-radius: 8px
  display: flex
  flex-direction: column
  gap: 5px
  align-items: center
  z-index: 9

  li
    cursor: pointer
    color: #00314D
  
.feature-cross-action
  position: absolute
  top: 17px
  right: 17px
  cursor: pointer

    

.add-feature-btn
  border: 1px dashed #00B8D9
  background: #E1FAFF
  width: 56px
  height: 56px
  border-radius: 50%
  color: #00B8D9
  font-size: 40px
  display: flex
  align-items: center
  justify-content: center
  cursor: pointer

.cap-text
  display: flex
  gap: 5px
  
  svg.dropdown-icon
    width: 15px