@import 'styles/vars'

.subscription-preview
  .subscription-preview-title
    font-weight: 600
    font-size: 20px
    color: #00314D

  .subscription-preview-content
    border: 1px solid #00B8D9
    padding: 24px
    box-shadow: 0px 20px 30px 0px #00314D26
    border-radius: 12px
    margin-top: 16px

    .subscription-point
      margin-bottom: 24px
      .title  
        font-weight: 400
        font-size: 16px
        color: #00314D
      
      .title-value
        font-weight: 600
        font-size: 20px
        color: #00314D
        margin-top: 5px

      .subs-features-list
        list-style: none
        margin-top: 10px

        .subs-features-item
          display: flex
          align-items: center
          gap: 10px
          margin-bottom: 10px

          .subs-features-icon
            width: 24px
            height: 24px

          .subs-features-text
            color: #60747E
            font-size: 18px

