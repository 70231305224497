.sign-in-google
  display: flex
  align-items: center
  justify-content: center
  margin-top:0px
  position: absolute
  top: 0
  bottom: 0
  height: 100%
  opacity: 0

  .text
    font-size: 17px
    font-weight: 500
    color: #3A3A3C

  .icon
    margin-right: 16px

.button.secondary
  padding: 0px
  background: #fff

.S9gUrf-YoZ4jf iframe
  max-width: 534px
  outline: none
  margin: 0px !important

.S9gUrf-YoZ4jf iframe .nsm7Bb-HzV7m-LgbsSe
    background: #fff
    width: 534px

.S9gUrf-YoZ4jf iframe div#container
  padding: 0px
.google-icon-custom .icon 
    width: auto
    height: auto
    border-radius: 50%
    margin-bottom: 0
    background: transparent