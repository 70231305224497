@use "sass:map"
@import 'styles/vars'

.navigation
  width: 100%
  box-sizing: border-box

  a
    display: inline-flex
    height: 50px
    width: 100%
    padding: 10px 16px
    box-sizing: border-box
    align-items: center
  

    &.active
      background: linear-gradient(90deg, rgba(0, 184, 217, 0.4) 0%, rgba(0, 212, 255, 0) 100%)

      img
        opacity: 1

      span
        color: #00B8D9

    img
      opacity: 0.6
      height: 24px
      margin-right: 16px

    span
      font-weight: 600
      color: #91BCD4  
      font-size: 18px

.sidebar-log
  padding: 20px 16px