@import 'styles/vars'

.add-staff-form-container
  display: flex
  justify-content: space-between
  width:100%

.buttons-container
  text-align: right

.invite-success
  display: flex
  justify-content: center
  flex-direction: column
  align-items: center

  .left
    flex-grow: 1
    width: 50%
    padding-right: 24px

  .right
    flex-grow: 1
    width: 50%
    padding-left: 24px
