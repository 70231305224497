@import 'styles/vars'

.label-value-table
  .row
    display: flex
    padding: 24px 0

    &:not(:last-child)
      border-bottom: $color-neutral-30 solid 1px

    .label
      color: $color-neutral-400
      font-size: 14px
      font-weight: 500

    .value
      color: $color-neutral-900
      font-weight: 400
