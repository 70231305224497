.customer-assets-container
    text-align: center
    width: 100%

.promotional-container
    background: white
    padding: 30px 20px
    max-width: 450px
    width: 100%
    margin: 0 auto 20px


    h2
        font-size: 50px
        max-width: 350px
        margin: 15px auto 25px 
        color: black
        line-height: 1

    h6
        font-size: 22px
    
    p
        font-weight: bold

    .cornerDiv
        position: relative
        width: max-content
        margin: 60px auto

    .corner 
      position: absolute
      width: 75px
      height: 75px
      border: 8px solid black
    

   
    .top-left 
      top: -25px
      left: -25px
      border-right: none
      border-bottom: none
    

   
    .top-right 
      top: -25px
      right: -25px
      border-left: none
      border-bottom: none
    

   
    .bottom-left 
      bottom: -25px
      left: -25px
      border-right: none
      border-top: none
    

   
    .bottom-right 
      bottom: -25px
      right: -25px
      border-left: none
      border-top: none
      