@import 'styles/vars'

.templates
  width: 100%
  height: 100%


  &-header
    height: 70px
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center
    margin-top: -24px
    border-bottom: 1px solid $color-neutral-40

    .left
      height: 100%

  .templates-body
    margin-top: 0px
    height: 100%

.add-app-template

  .form-container
    width: 100%
    display: flex
    height: 100%
    
    .left
      padding-right: 20px
      border-right: 1px solid #B7C5CC
      height: calc(100% - 80px)
      overflow-y: auto

    .right
      padding-left: 20px

    .left,.right
      width: 50%

    .wordcount
      text-align: right
      color: $color-neutral-300
      margin-bottom: 16px

    .buttons-container
      text-align: right

.app-templates-list
  padding-bottom:10px
  display: flex
  margin: 0 -10px
  flex-wrap: wrap
  &.task-templates
    flex-direction: column
    height: 100%
    margin: 0 
    & > .left
      width: 50%
      padding-right: 20px
      border-right: 1px solid #B7C5CC
      height: calc(100% - 145px)
      overflow-y: auto

      .left
        align-items: flex-start
        .title
          display: flex
          gap: 20px


    & > .right 
      width: 50%
    .app-template-item
      width: 100%
      margin: 10px 0



  .app-template-item
    background-color: $color-neutral-0
    border-radius: 12px
    border: 1px solid #E0E9ED
    box-shadow: 0px 20px 30px 0px #00314D0D
    margin-bottom: 12px
    padding: 20px 12px
    display: flex
    align-items: flex-start
    justify-content: space-between
    width: calc(33.33% - 20px)
    margin: 10px 10px 

    .left
      display: flex
      align-items: center

      .icon
        margin-right: 12px
        flex-shrink: 0 
        width: 56px
        height: 56px

        img
          width: 100%
          height: 100%
          padding: auto
          object-fit: contain
          border-radius: 50%

      .title
        font-weight: 400
        color: #00314D
        font-size: 22px
        margin-bottom: 8px

      .description
        color: #60747E
        font-weight: 14px

.add-email-template

  .email-form-container
    display: flex
    justify-content: space-between
    height: 100%

    .buttons-container
      text-align: right

    .left
      flex-grow: 1
      width: 50%
      padding-right: 24px
      overflow-y: auto
      height: calc(100% - 60px)
      padding-bottom: 20px
      border-right: 1px solid #B7C5CC
    .right
      flex-grow: 1
      width: 50%
      padding-left: 24px

.email-templates-list
  display: flex
  height: calc(100% - 180px)

  .list
    width: 50%
    padding-right: 24px
    height: 100%
    overflow-x: auto

  .preview
    width: 50%
    padding-left: 24px
    border-left: 1px solid #B7C5CC

  .email-template-item
    background-color: $color-neutral-0
    border-radius: 12px
    border: 1px solid #E0E9ED
    box-shadow: 0px 20px 30px 0px #00B8D90D
    padding: 24px
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 12px

    &.active
      border: 1px solid #00B8D9

    .left
      display: flex

      img
        margin-right: 12px
      .title
        font-size: 22px
        font-weight: 400
        margin-bottom: 9px
        color: #00314D

      .subject
        color: #60747E
        font-size: 16px




.templates-header
  display:flex
  flex-wrap: wrap
  height: auto
  margin: 10px 0 30px

  .bar
    width: 100%

    .primary.button
      padding: 12px 24px 12px 24px

  .right  
    display: flex
    align-items: center

    .label
      margin-right: 10px
      font-size: 18px
      font-weight: 600

select#selectOption
  width: 100%
  box-sizing: border-box
  padding: 12px 8px
  border: 1px solid #D6D9E1
  border-radius: 4px
  outline: none
  margin-bottom:15px
  
.cu_temp_name
  font-size:12px
  color:#505F79

.progress-badge
  display: flex
  font-size: 12px
  font-weight: 600
  border-radius: 14px
  padding: 5px 10px
  width: fit-content

.placeholder-selector
  position: relative

  select
    height: 60px
    appearance: none
  button
    position: absolute
    right: 7px
    background: #00314D!important