@import 'styles/vars'

.progress-bar
  display: flex
  align-items: center

  .progress-graph
    width: 82px

    background: $color-neutral-40
    height: 10px
    margin-right: 8px
    overflow: hidden
    transform: skewX(-20deg)

    .fill
      background-color: $color-theme-primary
      display: block
      height: 100%

  .percent
    color: #4B5563
