.container .body .detail .main-container
    overflow: auto
.user-subscription-container
  
    .tab-heading
        font-size: 18px
        font-weight: 600
        color: #80949D
        margin-bottom: 12px
        text-align: left
    .current-subscription
        padding: 20px 0
        min-height: 200px
        text-align: center
        

    .available-subscriptions
        width: 100%

    .subs-packages-container
        flex-wrap: wrap
        justify-content: flex-start
        .subs-package
            width: calc(25% - 16px)
            margin-bottom: 16px

            @media (max-width: 1600px)
                width: calc(33.33% - 16px)
            


