$template-bg-color: #f9f9f9
$template-border-color: #ddd
$template-label-color: #1F1F1F
$template-value-color: #1F1F1F
$template-font-size-label: 18px
$template-font-size-value: 18px

.template-section
  margin:25px 0 0

  .template-name
    font-size: 24px
    margin-bottom: 20px
    color: $template-value-color

  .template-details
    .detail-item
      display: flex
      margin-bottom: 15px

      .detail-label
        padding: 0 10px 0 0
        font-size: $template-font-size-label
        color: $template-label-color
        width: 350px // Adjust as needed for proper alignment

      .detail-value
        padding: 5px
        font-size: $template-font-size-value
        color: $template-value-color
        flex: 1.1
