@use "sass:map"
@import 'styles/vars'

.users .customer-content.customers_
  align-items: flex-start!important
  height: calc(100% - 60px)!important

  .list
    display: flex
    flex-wrap: wrap
    margin: 0 -10px
    margin-top: 20px
    width: calc(100% + 20px)!important
    align-content: baseline
    overflow-y: auto

  .staff-item
    width: calc(33.33% - 20px)!important
    margin: 10px
    box-shadow: 0px 20px 30px 0px #00314D0D
    border: 1px solid #E0E9ED
    border-radius: 12px
    padding: 12px 12px 24px 24px
    align-items: flex-start
    display: flex

    @media (max-width: 1366px)
      width: calc(50% - 20px)!important

  .details
    margin-left: 16px
  
  .bottom
    margin-top: 8px

  .name
    color: #00314D
    font-size: 22px
    margin-top: 0
    font-weight: 400
  
  .email
    color: #265068
    font-size: 14px
    font-weight: 500
  
  .progress-badge
    background: #E1FAFF
    color: #00B8D9
    font-size: 12px
    font-weight: 600
    border-radius: 14px
    padding: 5px 10px
    text-transform: capitalize

  .date
    color: #60747E
    font-size: 14px
    font-weight: 400 
  
  .left
    align-items: flex-start
    display: flex
    width: 100%